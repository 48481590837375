import React, { FC } from "react";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const LoadingPage: FC = () => {
	return (
		<Container>
			<CircularProgress />
		</Container>
	);
};
export default LoadingPage;
