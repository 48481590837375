/* globals window */
import React, { useEffect, useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import "firebase/auth";
import { setUserCookie } from "./userCookies";
import { mapUserData } from "./mapUserData";
import { fuego } from "@nandorojo/swr-firestore";

const firebaseAuthConfig = {
	signInFlow: "popup",
	// Auth providers
	// https://github.com/firebase/firebaseui-web#configure-oauth-providers
	signInOptions: [
		{
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
			requireDisplayName: true,
		},
		{
			provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
			requireDisplayName: true,
		},
		{
			provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
			requireDisplayName: true,
		},
	],
	signInSuccessUrl: "/",
	credentialHelper: "none",
	callbacks: {
		signInSuccessWithAuthResult: async ({ user }, redirectUrl) => {
			const userData = mapUserData(user);
			await fuego.db.collection("userProfiles").doc(`${user.uid}`).set(
				{
					username: user.displayName,
					email: user.email,
					createdAt: firebase.firestore.FieldValue.serverTimestamp(),
					updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
				},
				{ merge: true }
			);
			setUserCookie(userData);
		},
	},
};

const FirebaseAuth = () => {
	// Do not SSR FirebaseUI, because it is not supported.
	// https://github.com/firebase/firebaseui-web/issues/213
	const [renderAuth, setRenderAuth] = useState(false);
	useEffect(() => {
		if (typeof window !== "undefined") {
			setRenderAuth(true);
		}
	}, []);
	return (
		<div>
			{renderAuth ? (
				<StyledFirebaseAuth
					uiConfig={firebaseAuthConfig}
					firebaseAuth={firebase.auth()}
				/>
			) : null}
		</div>
	);
};

export default FirebaseAuth;
