import React, { useEffect } from "react";
import Head from "next/head";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { FuegoProvider } from "@nandorojo/swr-firestore";
import "@fontsource/roboto";
import "config/styles/app.css";
import { hotjar } from "react-hotjar";
import Fuego from "utils/fuego";
import {
	ThemeProvider,
	Theme,
	StyledEngineProvider,
	createTheme,
} from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "createEmotionCache";

import { useAuth } from "components/common/HOC/AuthProvider";
import { AuthProvider, ProtectRoute } from "components/common/HOC/AuthProvider";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

declare module "@mui/styles/defaultTheme" {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const theme = createTheme();

const firebaseConfig = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
};

// Also runs firebase.initializeApp
const fuego = new Fuego(firebaseConfig);

function MyApp({
	Component,
	emotionCache = clientSideEmotionCache,
	pageProps,
}): JSX.Element {
	const { user }: any = useAuth();

	useEffect(() => {
		if (process.env.NODE_ENV === "production") {
			hotjar.initialize(2258989, 6);
			hotjar.identify("USER_ID", { userProperty: user?.id });
		}

		// Remove the server-side injected CSS for MUI
		const jssStyles = document.querySelector("#jss-server-side");
		if (jssStyles) {
			jssStyles.parentElement.removeChild(jssStyles);
		}
	});
	return (
		<CacheProvider value={emotionCache}>
			<ThemeProvider theme={theme}>
				<>
					<Head>
						<meta
							name="viewport"
							content="width=device-width, initial-scale=1.0"
						></meta>
						<title>SPRK</title>
					</Head>
					<AuthProvider>
						<ProtectRoute>
							<FuegoProvider fuego={fuego}>
								<style jsx global>{`
									html,
									body {
										padding: 0;
										margin: 0;
										font-family: "Roboto", "Helvetica",
											"Arial", sans-serif;
									}

									* {
										box-sizing: border-box;
									}
								`}</style>
								<Component {...pageProps} />
							</FuegoProvider>
						</ProtectRoute>
					</AuthProvider>
				</>
			</ThemeProvider>
		</CacheProvider>
	);
}

export default MyApp;
