import FirebaseAuth from "utils/auth/FirebaseAuth";
import React, { FC, useState } from "react";
import { Box } from "@mui/material";

const AuthPage: FC = () => {
	const [SPRKLogoHover, setSPRKLogoHover] = useState(false);

	const setSPRKLogoHoverFalse = () => setSPRKLogoHover(false);
	const setSPRKLogoHoverTrue = () => setSPRKLogoHover(true);

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			style={{ height: "100vh" }}
		>
			<img
				width="200px"
				alt="Act Now logo"
				src={
					SPRKLogoHover
						? "/images/SPRKItalic.png"
						: "/images/SPRK.png"
				}
				onMouseOver={setSPRKLogoHoverTrue}
				onMouseLeave={setSPRKLogoHoverFalse}
				style={{ cursor: "pointer" }}
			/>

			<FirebaseAuth />
		</Box>
	);
};

export default AuthPage;
