export const mapUserData = (user) => {
	const { uid, displayName, email, xa } = user;
	return {
		id: uid,
		displayName,
		email,
		token: xa,
	};
};

// https://firebase.google.com/docs/auth/admin/manage-users#update_a_user
// Can also get photoUrl / emailVerified
