import React, { createContext, useState, useEffect, useContext } from "react";
import { useRouter } from "next/router";
import firebase from "firebase/app";
import "firebase/auth";
import {
	removeUserCookie,
	setUserCookie,
	getUserFromCookie,
} from "utils/auth/userCookies";
import { mapUserData } from "utils/auth/mapUserData";
import { LoadingPage } from "components/pages/AuthPage";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const router = useRouter();

	const logout = async () => {
		return firebase
			.auth()
			.signOut()
			.then(() => {
				// Sign-out successful.
				router.push("/login");
			})
			.catch((e) => {
				console.error(e);
			});
	};

	useEffect(() => {
		// Firebase updates the id token every hour, this
		// makes sure the react state and the cookie are
		// both kept up to date
		const cancelAuthListener = firebase.auth().onIdTokenChanged((user) => {
			if (user) {
				const userData = mapUserData(user);
				setUserCookie(userData);
				setUser(userData);
			} else {
				removeUserCookie();
				setUser(null);
			}
			setLoading(false);
		});

		const userFromCookie = getUserFromCookie();
		if (!userFromCookie) {
			router.push("/login");
			return;
		}
		setUser(userFromCookie);

		return () => {
			cancelAuthListener();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated: !!user,
				user,
				isLoading: loading,
				logout,
			}}
		>
			<div>{children}</div>
		</AuthContext.Provider>
	);
};

interface UseAuthProps {
	isAuthenticated: boolean;
	user: any;
	isLoading: boolean;
	logout: () => void;
}

export const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
	const router = useRouter();
	// @ts-expect-error
	const { isAuthenticated, isLoading } = useAuth();
	if (isLoading || (!isAuthenticated && router.pathname !== "/login")) {
		return <LoadingPage />;
	}
	return children;
};
